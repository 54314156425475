import {combineReducers} from 'redux'

import SidebarControlReducer from './sidebar/sidebarControl/sidebarControlReducer'
import userReducer from './users/userReducers'
import roleReducer from './roles/roleReducers'
import DbReducer from './dbcredentials/dbReducers'
import appReducer from './app/appReducers'
import AllowedTableReducer from './allowedTables/reducers'
import appTableReducer from './appPermission/appPermissionReducer'
import masterdataReducer from './masterdata/masterdataReducers'
import { TablePermReducer } from './appColumnPermission/reducer'
import roleForuserReducer from './sidebar/roleForUser/RoleForUserReducers'
import { AppForRoleReducer } from './sidebar/appForRole/appForRoleReducers'

import CurrentUserReducer from './userProfile/userProfileReducers'
import { currentAppTableReducer } from './currentAppTable/currentAppTableReducer'
import modalReducer from './modals/modelReducer'
import LoaderReducer from './loader/loaderReducer'
import XentralReducer from './Xentral/xentralReducer'

const RootReducer=combineReducers({
    users:userReducer,
    roles:roleReducer,
    dbcredentials:DbReducer,
    apps:appReducer,
    allowedTables:AllowedTableReducer,
    appTables:appTableReducer,
    masterdata: masterdataReducer,
    colPermissions:TablePermReducer,
    roleForUser:roleForuserReducer,
    appsForRoles:AppForRoleReducer,
    CurrentUser:CurrentUserReducer,
    currentAppTable:currentAppTableReducer,
    modals:modalReducer,
    loader:LoaderReducer,
    xentral:XentralReducer,
    sidebarControl:SidebarControlReducer
    
})

export default RootReducer