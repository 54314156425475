import React from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { UpdateTableRowDataAction } from "../../../../Redux/masterdata/masterdataActions";
import validationSchemaMapper from "../../MasterDataCrud/validationSchemaMapper";
import { useFormik } from "formik";


import {
  crossIcon,
  noti_info_error,
} from "../../../../styling_files/newUiStyles/icons";

const WareneingangUpdate = ({
  appID,
  allowedTableID,
  updateDialogueInputFields,
  setUpdateDialogueInputFields,
  selectedRow,
  setSelectedRow,
}) => {
  const dispatch = useDispatch();

  let inputFieldsValues = { ...updateDialogueInputFields.values };
  let inputFieldsTypes = { ...updateDialogueInputFields.types };
  let inputFieldIsNullable = { ...updateDialogueInputFields.nullable };

  const inputFieldHasDefault = { ...updateDialogueInputFields.has_default };

  //replace null values with ''

  const formikValues = Object.keys(inputFieldsValues).filter(item=>item!='user_id').reduce((acc, key) => {
    acc[key] = inputFieldsValues[key] === null ? "" : inputFieldsValues[key];
    return acc;
  }, {});
 

  //handling date time values
  const handleDateTimeChange = (e) => {
    const dt_with_Seconds = e.target.value + ":00";
      formik.handleChange(e);
      formik.setFieldValue(e.target.name, dt_with_Seconds);

  };

  //update action: pass from props

  const onSubmit = (values) => {
    dispatch(
      UpdateTableRowDataAction(appID, allowedTableID, selectedRow.id, values)
    );
    let update_info = { ...updateDialogueInputFields };
    update_info.show = false;
    setUpdateDialogueInputFields(update_info);
    formik.resetForm();

    setSelectedRow({});
  };

  const formik = useFormik({
    initialValues: formikValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema: formikValues
      ? validationSchemaMapper(
          inputFieldsTypes,
          inputFieldIsNullable,
          inputFieldHasDefault
        )
      : null,
  });

  const inputFieldNames = inputFieldsValues
    ?Object.keys(inputFieldsValues).filter(item=>item!='user_id')
    : [];

  if (!updateDialogueInputFields.show) {
    return null;
  }
  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>

      <div className="modelForm">
        <div className="dialog-header">
          <div className="dialog-title">Update</div>
          <div className="dialog-close">
            <img
              src={crossIcon}
              onClick={() => {
                let update_info = { ...updateDialogueInputFields };
                update_info.show = false;
                setUpdateDialogueInputFields(update_info);
                formik.resetForm();

                formik.resetForm();
              }}
            />
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
          className="dialog-form"
        >
          <div className="dialog-form-fields">
            {inputFieldNames.map((data) => {
              
              return (
                <div className="form-fields" key={Math.random()}>
                  <label htmlFor={data} className="input-label">
                    {data} :
                  </label>

                  {(() => {
                    switch (data) {
                      default:
                        return (
                          <>
                            <div className="dialog-input-container">
                              <input
                                type={inputFieldsTypes[data]}
                                className="form-control my-1"
                                id={data}
                                name={data}
                                value={formik.values[data]}
                                onBlur={formik.handleBlur}
                                onChange={
                                  inputFieldsTypes[data] === "datetime-local"
                                    ? handleDateTimeChange
                                    : formik.handleChange
                                }
                              />
                              {formik.touched[data] && formik.errors[data] && (
                                <img
                                  className="input-error-icon"
                                  src={noti_info_error}
                                  width="15px"
                                  height="15px"
                                />
                              )}
                            </div>

                            <div>
                              {formik.touched[data] && formik.errors[data] ? (
                                <div className="error">
                                  {formik.errors[data]}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                    }
                  })()}
                </div>
              );
            })}
          </div>

          <div className="dialog-submit">
            <button type="submit" className="Btn btn-submit">
              Save changes
            </button>
          </div>
        </form>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default WareneingangUpdate;
