import React from "react";
import { useParams } from "react-router-dom";

import AirflowTest from "../../components/crud/MasterDataCrud/specialTableCrud/airflowTestCrud";
import WareneingangCrud from "../../components/crud/MasterDataCrud/specialTableCrud/wareneingangCrud";

import NoCustomView from "./noCustomView";

export const CustomTableRouteMapping = () => {
  let { tableName } = useParams();
  let { appName } = useParams();

  switch (tableName) {

    case 'Wareneingang':
      return <WareneingangCrud/>

    case "AirflowTest":
      return <AirflowTest />;

    default:
      return <NoCustomView appName={appName} tableName={tableName} />;
  }
};

export const JobRunnerAppMapping=()=>{
  return <AirflowTest />;

}

