

import React, { useState, useEffect,useRef} from "react";


import { UpdateUserAction } from "../../../Redux/users/userActions";
import { addRoute } from "../../../Redux/modals/modalActions";


import UserCreateDialog from "./UserCreateDialog";
import UserUpdateDialog from "./UserupdateDialog";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { useDispatch, useSelector } from "react-redux";
import {
  GetUserAction,
  DeleteUserAction,
} from "../../../Redux/users/userActions";

import { SetCurrentAppTableAction } from "../../../Redux/currentAppTable/actions";
import {ClearDagState} from '../../../Redux/loader/LoaderAction'
import {
  onFilterChange,
  onFilterTextChange,
  RenderState,
  handleReactPagination,
  onDragStopped,
 
} from "../tableState";

//importing crud components
import AgGridPageSize from "../crudTableComponents/agGridPageSize";


import SearchField from "../crudTableComponents/searchField";
import PaginationInfo from "../crudTableComponents/pagintaiton";


import {downloadIcon,plusIcon,editIcon,deleteIcon} from '../../../styling_files/newUiStyles/icons'
import { CellEditorDropdown,CellRendererForBool } from "../crudTableComponents/cellEditor";

import { BreadCrumbs } from "../crudTableComponents/breadCrumbs";


import { openUser,closeUser } from "../../../Redux/modals/modalActions";


const UserCrud= () => {
  const table = "user"; 
  const userModalOpen=useSelector(state=>state.modals.userModalOpen)
  const totalPageSaved = localStorage.getItem(`${table}_totalPage`);

  let [pageInfo,setPageInfo]=useState({currentPage:0,totalPage:totalPageSaved||1})
  let [initialTotalPage,setInitialTotalPage]=useState(0)

  let [quickFilterText, setQuickFilterText] = useState(null);

  let [pageSize, setPageSize] = useState(null);

  let [selectedID, setSelectedID] = useState(null);

  let [showUpdateModal, setShowUpdateModal] = useState(false);

  const dispatch = useDispatch();
  const gridRef=useRef()

  const responseData = useSelector((state) => state.users.users);

  useEffect(() => {
   
    dispatch(GetUserAction());
   
  }, []);

  useEffect(() => {
    dispatch(
      SetCurrentAppTableAction({
        currentTable: "User Table",
        currentApp: "Settings",
      })
    );
    dispatch(ClearDagState())
  }, []);



 


   //grid api states
   const [gridApi, setGridApi] = useState(null);
   const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(()=>{
    let column_state = JSON.parse(localStorage.getItem(`${table}_column_state`));
    gridColumnApi?.applyColumnState({state:column_state, applyOrder: true});

  },)

  const onFirstDataRendered = (params) => {
    RenderState(params, table, setQuickFilterText, setPageSize);
  };

 

  const onCellEditingStarted = () => {
   
  };

  const onCellEditingStopped = () => {
   
  };




  //column definations
  const columnDefsData = [
    {
      headerName: "User ID",
      field: "id",
      checkboxSelection: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Name",
      field: "full_name",
      editable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Email",
      field: "email",
      editable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Password",
      field: "password",
      valueFormatter: (params) => {
        return "********";
      },
    },
    {
      headerName: "Active",
      field: "is_active",
      editable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["equals", "notEqual"],
        suppressAndOrCondition: true,
      },
      
      cellRenderer:CellRendererForBool,
      cellEditor: CellEditorDropdown,
      cellEditorParams: {
        values: ['Yes','No'],
      },
    },
    {
      headerName: "Superuser",
      field: "is_superuser",
      editable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["equals", "notEqual"],
        suppressAndOrCondition: true,
      },
      cellRenderer:CellRendererForBool,
      cellEditor: CellEditorDropdown,
      cellEditorParams: {
        values: ['Yes','No'],
      },
    },
  ];

  //dafault column def
  const defaultColDefs = {
    minWidth: 200,
    headerClass: "header-class",
    cellClass: "cell-class",
    flex: 1,
    sortable: true,
    resizable:true,
  

    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="color:white"/>',
      sortDescending: '<i class="fa fa-sort-down" style="color:white"/>',
    },
  };

  const onRowDataChanged = (params) => {
    setInitialTotalPage(params.api?.paginationGetTotalPages())
  };

  //grid Ready
  const onGridReady = (params) => {
    RenderState(params, table, setQuickFilterText, setPageSize);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
   
  };

  //handling selection of row

  const onSelectionChanged = (e) => {
    const row = e.api.getSelectedRows();
    const selectedid = row[0] ? row[0].id : null;
    setSelectedID(selectedid);
  };

  //delete user

  const DeleteUser = () => {
    if (selectedID) {
      const confirm = window.confirm(
        `Do you want to delete the user?`
      );
      if (confirm) {
        dispatch(DeleteUserAction(selectedID));
        setSelectedID(null);
      }
    }
  };

  //modal controls
 

  const toUserUpdateForm = () => {
    setShowUpdateModal(true);
  };

  //page size

  const onPageSizeChange = (pgSize) => {
    gridApi.paginationSetPageSize(Number(pgSize));
    setPageSize(pgSize);
    localStorage.setItem(`${table}_currentPageSize`, JSON.stringify(pgSize));

    //current page state change

    handleReactPagination(gridApi, pageInfo, setPageInfo, table);
  };

  //Exporting

  const onExportasCSV = () => {
    gridApi.exportDataAsCsv();
  };

  const onCellValueChanged = (params) => {
    const value = {};
    const field_name = params.colDef.field;
    const new_value = params.value;
    value[field_name] = new_value;
    gridRef.current.api.deselectAll()

   

    dispatch(UpdateUserAction({updateData:value, id:params.data.id,gridRef:gridRef}));
    
 
  };



  //opening and closing create modal

  const handleOpenUser=()=>{
    dispatch(openUser())
    dispatch(addRoute("/dashboard/settings/users"))

  }

  const handleCloseUser=()=>{
    dispatch(closeUser())
  }

 
 
  return(
    <>
    <BreadCrumbs/>

  <div className="title-layout">
    <div className='title-container'>
    <div className='current-app-table'>
            <p>Users Table</p>

    </div>
    </div>
   
  </div>
  <div className='actions-layout'>
    <div className='actions-container-backdrop'>
      
      <div className='actions-container'>
      <div className="searchfield-container">
        <span className="button-label">Search Data</span>
        <SearchField
          quickFilterText={quickFilterText}
          setQuickFilterText={setQuickFilterText}
          onFilterTextChange={onFilterTextChange}
          gridApi={gridApi}
          table={table}
        />
        </div>
       
       
        <div className="pagesize-container" >
        <span className="button-label">Rows</span>
       
          <AgGridPageSize 
           pageSize={pageSize}
           onPageSizeChange={onPageSizeChange}
           
          />
       
       </div>
        
       
        <div className="line-seperator-container">
        
        <div className="line-seperator"></div>   
        </div>

        <div className="button-container">
             
          <span className="button-label"></span>
          {/* export button */}
          <button 
          className="Btn DownloadBtn "
          onClick={onExportasCSV}
          >   
          <div>
            <img 
            className="mx-1 mb-1"
            src={downloadIcon}
            alt='csv'
             width='17px' 
             height='17px'
             />
            <span className="mx-1">Export CSV</span>
            </div>
          </button>
        </div>

        <div className="line-seperator-container">
        
        <div className="line-seperator"></div>   
        </div>


           {
            selectedID?

            <>
            <div className="button-container">
            <span className="button-label"></span>
            <button 
              className="Btn deleteBtn"
              onClick={DeleteUser}>
            <div>
            <img 
            className="mx-1 mb-1"
            src={deleteIcon} 
            alt='delete'
             width='15px' 
             height='15px'
             />
            <span className="mx-1">Delete</span>
            </div>
            
          </button>

            </div>
            
            <div className="button-container">
            <span className="button-label"></span>
            <button 
              className="Btn updateBtn "
              onClick={toUserUpdateForm}
            >

            <div>
            <img 
            className="mx-1 mb-1"
            src={editIcon}
           
            alt='new'
            width='14px' 
            height='14px'
            
             />

            <span className="mx-1">Edit</span>
            
            </div>
          </button>

            </div>
         
          </>


          
          :

          <div className="button-container">
          <span className="button-label"></span>
          <button 
              className="Btn createBtn"
              onClick={()=>handleOpenUser()}
            >

            <div>
            <img 
            className="mx-1 mb-1"
            src={plusIcon}
            alt='new'
            width='14px' 
            height='14px'
            title='New'
             />
            
            </div>
            <span className="mx-1">New</span>
           
          </button>

          </div>
      
          } 

      </div>

      
      
    </div>


  </div>

 {/* user create dialog */}
  <div >
        <UserCreateDialog
         userModalOpen={userModalOpen}
         closeUserModal={handleCloseUser}
        
        />
  </div>

  {/* user update dialog */}
  <div>
    <UserUpdateDialog
     selectedID={selectedID}
     setSelectedID={setSelectedID}
     showModal={showUpdateModal}
     setShowModal={setShowUpdateModal}
     gridRef
    />
  </div>

  <div className="table-layout">
    <div className='table-container'>

    <div
          className="ag-theme-alpine"
          style={{ height: "100%", width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={responseData}
            columnDefs={columnDefsData}
            rowSelection="single"
            // rowMultiSelectWithClick={true}
            onSelectionChanged={onSelectionChanged}
            defaultColDef={defaultColDefs}
            onGridReady={onGridReady}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
            onCellValueChanged={onCellValueChanged}
            onCellEditingStarted={onCellEditingStarted}
            onCellEditingStopped={onCellEditingStopped}
            headerHeight={45}
            rowHeight={45}
            pagination={true}
            suppressPaginationPanel={true}
            paginationPageSize={pageSize}
            suppressDragLeaveHidesColumns={true}

            onFirstDataRendered={(params) => onFirstDataRendered(params)}
            onFilterChanged={(params) => onFilterChange(params, table)}
            onRowDataChanged={(params)=>onRowDataChanged(params)}


            onDragStopped={(params) => onDragStopped(params, table)}
            onSortChanged={(params) => onDragStopped(params, table)}
            undoRedoCellEditing={true}
          ></AgGridReact>
    </div>
    </div>
    
 
      
      
    <PaginationInfo
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        gridApi={gridApi}
        table={table}
        totalPage={initialTotalPage}
      />
      
      
       
      
   
  </div>

    </>
)
};

export default UserCrud;
