import { AxiosRequest } from "../rootApi";


export const SendToAirFlowWareneingang=(table_name)=>{
    return AxiosRequest ({url:`/api/v1/Wareneingang/${table_name}/send_to_airflow`,method:'GET',timeout:9999999})

}


export const WareneingangGetRows=(table_name)=>{
    return AxiosRequest ({url:`/api/v1/Wareneingang/${table_name}/read_rows`,method:'GET',timeout:9999999})

}


export const WareneingangCreateRows=(table_name,data)=>{
    return AxiosRequest ({url:`/api/v1/Wareneingang/${table_name}/create_row`,method:'POST',params:data,timeout:9999999})

}
  

