import React from "react";
import { useSelector,useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import { userProfileIcon } from '../../../styling_files/newUiStyles/icons'

import Auth from "../../users_auth/auth";

//importing actions
import { ClearCurrentUserAction } from "../../../Redux/userProfile/userProfileActions";
import { ClearRoleForUserAction } from '../../../Redux/sidebar/roleForUser/RoleForUserActions' 
import { ClearAppForRoleAction } from '../../../Redux/sidebar/appForRole/appForRoleAction'
import { ClearCurrentAppTable } from '../../../Redux/currentAppTable/actions'
import { ClearDagState } from '../../../Redux/loader/LoaderAction'

const UserInfo = () => {

    const dispatch=useDispatch()
    const navigate=useNavigate()
    const currentUser = useSelector(state => state.CurrentUser?.CurrentUser)
    const {logOut}=Auth()

    const handleLogout=()=>{
        dispatch(ClearCurrentUserAction())
        dispatch(ClearRoleForUserAction())
        dispatch(ClearAppForRoleAction())
        dispatch(ClearCurrentAppTable())
        dispatch(ClearDagState())
        navigate('/')
        logOut()
    }

    return (
        <>
            <div className="userInfo-Container">
                <div className="userInfo">
                    <p>{currentUser?.full_name}</p>
                    <span>{currentUser?.email}</span>


                </div >
                <div className='mb-2 mx-4' id='logout-btn'>
                    <img
                        src={userProfileIcon}
                        alt='user-profile'
                        width='19px'
                        height='19px'
                        onClick={handleLogout}
                        
                    />
                </div>


            </div>
        </>
    )
}

export default UserInfo