
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";




const AgGridTable=(props)=>{
    const {
        gridstyle,
        gridRef,
        gridOptions,
        tableRowsData,
        columnDefsData,
        onSelectionChanged,
        defaultColDefs,
        onGridReady,
        onCellEditingStopped,
        onCellEditingStarted,
        onRowDataChanged,
        onFirstDataRendered,
        onFilterChange,
        onDragStopped,
        table,
    
       
    }=props

  
 

      
   



   return (
    <>
       
                <div
                className="ag-theme-alpine"
                style={gridstyle?gridstyle:{ height: "100%", width: "100%"}}
                >
            
                    <AgGridReact
                        
                        ref={gridRef}
                        gridOptions={gridOptions}
                        rowData={tableRowsData}
                        columnDefs={columnDefsData}
                        rowSelection="single"
                        rowMultiSelectWithClick={true}
                        onSelectionChanged={onSelectionChanged}
                        defaultColDef={defaultColDefs}
                        
                        onGridReady={onGridReady}
                        onCellValueChanged={onCellEditingStopped}
                        onCellEditingStarted={onCellEditingStarted}
                        alwaysShowHorizontalScroll={true}
                        alwaysShowVerticalScroll={true}
                        headerHeight={45}
                        rowHeight={45}
                        pagination={true}
                        suppressPaginationPanel={true}
                        // paginationPageSize={pageSize}
                        suppressDragLeaveHidesColumns={true}

                        onRowDataChanged={(params)=>onRowDataChanged(params)}
                        onFirstDataRendered={(params) => onFirstDataRendered(params)}

                        onDragStopped={(params)=>onDragStopped(params,table)}
                        onSortChanged={(params) => onDragStopped(params, table)}
                        onFilterChanged={(params)=>onFilterChange(params,table)}

                        undoRedoCellEditing={true}
                        
                    
                        
                                    
                    > 
                    </AgGridReact>
        </div>
    

   
     

    </>

    
   )
  
}


export default AgGridTable