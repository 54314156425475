import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import {useSelector} from 'react-redux'

import AppGrid from "./AppGrid";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserCrud from "../components/crud/userCrud/UserCrud";

import RoleCrud from "../components/crud/roleCrud/roleCrud";

import DbCrud from "../components/crud/dbCrud/DbCrud";

import AllowedTable from "../components/mappings/tables/allowedTable";

import AppCrud from "../components/crud/appCrud/appCrud";

import AppTable from "../components/mappings/appPermission/appTable";

import Sidebar from "../components/sidebar/Sidebar";

import MasterDataCrud from "../components/crud/MasterDataCrud/MasterDataCrud";

//custom table routes

import AppTableRoutes from "../routes/customTableRoutes/appTableRoute";

import {CustomTableRouteMapping,JobRunnerAppMapping} from "../routes/customTableRoutes/customTableRoutesMapping";

import TableParmissionGrid from "../components/mappings/appPermission/dialog/permissionDialog";
import AirflowConfigDialog from "../components/crud/appCrud/airflowConfigModal";

//new UI import
import DashboardView from "../NewUiComponents/Dashboard/DashboardView";
import SidebarView from "../components/sidebar/sidebar_updated/sidebar";
import SettingsView from "../NewUiComponents/settings/settingsView";
import UserProfileView from "../NewUiComponents/Dashboard/userProfileView/userProfileView";
import JobRunnerView from "../components/jobRunner/jobRunnerView";

//loader component

import LoaderView from "../components/crud/crudTableComponents/loaderView";


const Main = () => {

  const [selectedApp, setselectedApp] = useState();

  const [dbCredentials, setdbCredentials] = useState([]);

  //loading message and state
  const isLoading=useSelector(state=>state.loader.isLoading)
  const message=useSelector(state=>state.loader.message)
  




  

  return (
    

    <LoaderView isLoading={isLoading} message={message}>
    <div className="grid-container">
      {/* sidebar */}
      <SidebarView />

      {/* use router here */}

      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<DashboardView />} />

        <Route path="/dashboard/settings" element={<SettingsView />} />

        <Route path="/profile" element={<UserProfileView />} />
        <Route path="dashboard/settings/apps" element={<AppCrud />} />
        <Route path="dashboard/settings/users" element={<UserCrud />} />
        <Route path="dashboard/settings/roles" element={<RoleCrud />} />
        <Route path="dashboard/settings/dbcredentials" element={<DbCrud />} />


        {/* app table route */}
        <Route
          path="/masterdata/:app_type/:appName/:appID/:tableName/:allowedTableID"
          element={<AppTableRoutes />}
        />

        {/* route for generic tables */}

        <Route
          path="/generictable/:appName/:appID/:tableName/:allowedTableID"
          element={<MasterDataCrud />}
        />

        {/* route for custom tables */}

        <Route
          path="/customtable/:appName/:appID/:tableName/:allowedTableID"
          element={<CustomTableRouteMapping />}
        />

        <Route
          path="/jobrunner/:appName/:appID/:tableName/:allowedTableID"
          element={<JobRunnerAppMapping />}
        />
         <Route
            path="/dashboard/settings/dbcredentials/allowedtables/:selectedID/"
            element={<AllowedTable />}
          />
          <Route path="/dashboard/settings/apps/:appName/:appID" element={<AppTable />} />

          <Route path="/dashboard/settings/apps/attach/:appID" element={<AppTable />} />
         
          <Route
            path="/dashboard/settings/apps/:appName/:appID/:dbID/:tablename/:allowed_table_id"
            element={<TableParmissionGrid/>}
          />
          <Route path='/dashboard/jobrunner'  element={<JobRunnerView/>}/>
      </Routes>



      <ToastContainer className="toastContainer" />
    </div>
    </LoaderView>
  );
};

export default Main;
