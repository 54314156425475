
import { SuccessAlert } from "../../../components/notifications/notifications";

import { errorHandler } from "../../../api/errorHandler";

import { HideLoaderAction,GetLastDagStateAction } from "../../loader/LoaderAction";

import { SendToAirFlowWareneingang,WareneingangCreateRows,WareneingangGetRows } from "../../../api/customTableApi/wareneingangAirflowApi";

import { POST_TABLE_ROW_DATA_ACTION,GET_TABLE_ROWS_DATA_ACTION } from "../masterdataActionTypes";

export const SendToAirFlowWareneingangAction=(appName,table_name)=>{

    return async function (dispatch) {
        return SendToAirFlowWareneingang(table_name)
          .then((response) => {
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            SuccessAlert(response.data.detail)
           
          })
          .catch(error=>{
            dispatch(GetLastDagStateAction(appName,table_name))
            dispatch(HideLoaderAction())
            errorHandler(error)
          })
      };

  }

export   const WareneingangCreateRowAction = (table_name, data) => {
    return async function (dispatch) {
      return WareneingangCreateRows(table_name, data)
      .then(
        (response) => {
          dispatch({
            type: POST_TABLE_ROW_DATA_ACTION,
            payload: response.data,
          });
          SuccessAlert('Row created')
        }
      )
      .catch(error=>{
          errorHandler(error)
          })
    };
  };


export const WareneingangGetRowsDataAction = (table_name) => {
    return async function (dispatch) {
      return WareneingangGetRows(table_name)
        .then((response) => {
          dispatch({
            type: GET_TABLE_ROWS_DATA_ACTION,
            payload: response.data,
          });
        })
        .catch(error=>errorHandler(error))
    };
  };