import React from 'react'
import Auth from './auth'

import {useDispatch} from 'react-redux'
import { ClearCurrentUserAction } from '../../Redux/userProfile/userProfileActions'
import { ClearRoleForUserAction } from '../../Redux/sidebar/roleForUser/RoleForUserActions' 
import { ClearAppForRoleAction } from '../../Redux/sidebar/appForRole/appForRoleAction'
import { ClearCurrentAppTable } from '../../Redux/currentAppTable/actions'
import { ClearDagState } from '../../Redux/loader/LoaderAction'
import { Navigate, useNavigate } from 'react-router-dom'

function LogOut() {

  const {logOut}=Auth()
  const dispatch=useDispatch()
  const navigate=useNavigate()

  const handleClick=()=>{

    dispatch(ClearCurrentUserAction())
    dispatch(ClearRoleForUserAction())
    dispatch(ClearAppForRoleAction())
    dispatch(ClearCurrentAppTable())
    dispatch(ClearDagState())
    navigate('/')
    logOut()

  }
    
  
  return (
    <div>
        <button className='Btn logoutBtn' onClick={handleClick}>
         <span className='mx-3'>Log Out</span> 
        </button>
    </div>
  )
}

export default LogOut