import React, { useState, useEffect, useRef } from "react";

import { UpdateAppAction } from "../../../Redux/app/appActions";


import { useNavigate } from "react-router-dom";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { useDispatch, useSelector } from "react-redux";

import AppUpdateDialog from "./appUpdateDialog";
import AppCreateDialog from "./appCreateDialog";


import { GetAppAction, DeleteAppAction } from "../../../Redux/app/appActions";

import { SetCurrentAppTableAction } from "../../../Redux/currentAppTable/actions";

import SearchField from "../crudTableComponents/searchField";
import NewPagination from "../crudTableComponents/newUIPagination";
import PaginationInfo from "../crudTableComponents/pagintaiton";

import {
  downloadIcon,
 
  plusIcon,
  editIcon,
  deleteIcon,
  tableIcon
} from "../../../styling_files/newUiStyles/icons";
import {
  CellEditorDropdown,
  CellRendererForDW,
  CellRendererForBool,
} from "../crudTableComponents/cellEditor";

import { BreadCrumbs } from "../crudTableComponents/breadCrumbs";

import { openApp,closeApp,addRoute } from "../../../Redux/modals/modalActions";

import {
  onFilterChange,
  onFilterTextChange,
  RenderState,
  handleReactPagination,
  onDragStopped,
} from "../tableState";

//importing crud components
import AgGridPageSize from "../crudTableComponents/agGridPageSize";

const AppCrud = () => {
  const table = "app";
  const totalPageSaved = localStorage.getItem(`${table}_totalPage`);

  const appModalOpen=useSelector(state=>state.modals.appModalOpen)
 

  let [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    totalPage: totalPageSaved || 1,
  });

  let [initialTotalPage,setInitialTotalPage]=useState(0)

  let [quickFilterText, setQuickFilterText] = useState(null);
  let [pageSize, setPageSize] = useState(10);

  let [selectedID, setSelectedID] = useState(null);
  let [currentApp, setCurrentApp] = useState({ appName: "", app_type: "" });


  let [showUpdateModal, setShowUpdateModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef = useRef();

  let responseData = useSelector((state) => state.apps.apps);

  useEffect(() => {
  
    dispatch(GetAppAction());
   
  }, []);

  useEffect(() => {
    dispatch(
      SetCurrentAppTableAction({
        currentTable: "App Table",
        currentApp: "Settings",
      })
    );
  }, []);

  //grid api states
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(() => {
    let column_state = JSON.parse(
      localStorage.getItem(`${table}_column_state`)
    );
    gridColumnApi?.applyColumnState({ state: column_state, applyOrder: true });
  });

  const onFirstDataRendered = (params) => {
    RenderState(params, table, setQuickFilterText, setPageSize);
  };

  // Define the initial grid options
  const [gridOptions, setGridOptions] = useState({
    suppressCellDeselection: false,
  });

  // Define the onCellEditingStarted and onCellEditingStopped event handlers
  const onCellEditingStarted = () => {
    setGridOptions({
      ...gridOptions,
      suppressCellDeselection: true,
    });
  };

  const onCellEditingStopped = () => {
    setGridOptions({
      ...gridOptions,
      suppressCellDeselection: false,
    });
  };

  //column definations
  const columnDefsData = [
    {
      headerName: "APP ID",
      field: "id",
      checkboxSelection: true,
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "APP Name",
      field: "application_name",
      editable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Description",
      field: "application_description",
      editable: true,
      filter: "agTextColumnFilter",
    },

    {
      headerName: "Application Type",
      field: "application_type",
      editable: true,
      cellRenderer: CellRendererForDW,
      cellEditor: CellEditorDropdown,
      cellEditorParams: {
        values: ["crud", "airflow", "custom"],
      },
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Hide Primary Key",
      field: "hide_primary_key",
      editable: true,
      cellRenderer: CellRendererForBool,
      cellEditor: CellEditorDropdown,
      cellEditorParams: {
        values: ["Yes", "No"],
      },
      filter: "agTextColumnFilter",
    },
  ];
  //dafault column def
  const defaultColDefs = {
    minWidth: 200,
    headerClass: "header-class",
    cellClass: "cell-class",
    flex: 1,
    sortable: true,
    resizable: true,
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="color:white"/>',
      sortDescending: '<i class="fa fa-sort-down" style="color:white"/>',
    },
  };




  const onRowDataChanged = (params) => {
    setInitialTotalPage(params.api?.paginationGetTotalPages())
  };

  //grid Ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  //handling selection of row

  const onSelectionChanged = (e) => {
    const [row] = e.api.getSelectedRows();
    const app_name = row?.application_name;
    const app_type = row?.application_type;
    const selectedid = row?.id;

    setSelectedID(selectedid);
    setCurrentApp({ appName: app_name, app_type: app_type });
  };

  //delete user

  const DeleteApp = () => {
    if (selectedID) {
      const confirm = window.confirm(
        `Do you want to delete the app?`
      );
      if (confirm) {
        setSelectedID(null);
        dispatch(DeleteAppAction(selectedID));
      }
    } else {
      alert("please Select a row");
    }
  };

  //handling modals
  const handleAppModalOpen=()=>{
    dispatch(openApp())
    dispatch(addRoute("/dashboard/settings/apps"))
  }

  const handleAppModalClose=()=>{
    dispatch(closeApp())
  }

  const toAppUpdateForm = () => {
    setShowUpdateModal(true);
  };

  const toAppTables = () => {
    navigate(`${currentApp.appName}/${selectedID}/`);
  };

  const toAirflowConfig = () => {
    navigate("/apps/airflowconfig");
  };

  //page size

  const onPageSizeChange = (pgSize) => {
    gridApi.paginationSetPageSize(Number(pgSize));
    setPageSize(pgSize);
    localStorage.setItem(`${table}_currentPageSize`, JSON.stringify(pgSize));

    //current page state change

    handleReactPagination(gridApi, pageInfo, setPageInfo, table);
  };

  //Exporting

  const onExportasCSV = () => {
    gridApi.exportDataAsCsv();
  };

  //calling update api after cell value changed

  const onCellValueChanged = (params) => {
    const updateData = params.data;
    dispatch(UpdateAppAction(updateData, params.data.id, gridRef));
    gridRef.current.api.deselectAll();
  };

  return (
    <>
      <BreadCrumbs />

      <div className="title-layout">
        <div className="title-container">
          <div className="current-app-table">
            <p>Apps Table</p>
          </div>
        </div>
      </div>

      <div className="actions-layout">
        <div className="actions-container-backdrop">
          <div className="actions-container">
            <div className="searchfield-container">
              <span className="button-label">Search Data</span>
              <SearchField
                quickFilterText={quickFilterText}
                setQuickFilterText={setQuickFilterText}
                onFilterTextChange={onFilterTextChange}
                gridApi={gridApi}
                table={table}
              />
            </div>

            <div className="pagesize-container">
              <span className="button-label">Rows</span>

              <AgGridPageSize
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </div>

            <div className="line-seperator-container">
              <div className="line-seperator"></div>
            </div>

            <div className="button-container">
              <span className="button-label"></span>
              {/* export button */}
              <button className="Btn DownloadBtn " onClick={onExportasCSV}>
                <div>
                  <img
                    className="mx-1 mb-1"
                    src={downloadIcon}
                    alt="csv"
                    width="17px"
                    height="17px"
                  />
                  <span className="mx-1">Export CSV</span>
                </div>
              </button>
            </div>
            <div className="line-seperator-container">
              <div className="line-seperator"></div>
            </div>

            {selectedID ? (
              <>
              {/* tables */}
                <div className="button-container">
                  <span className="button-label"></span>
                  <button className="Btn updateBtn " onClick={toAppTables}>
                    <div>
                      <img
                        className="mx-1 mb-1"
                        src={tableIcon}
                        alt="attach-tables"
                        width="14px"
                        height="14px"
                      />

                      <span className="mx-1">Tables</span>
                    </div>
                  </button>
                </div>
                {/* delete */}
                <div className="button-container">
                  <span className="button-label"></span>
                  <button className="Btn deleteBtn" onClick={DeleteApp}>
                    <div>
                      <img
                        className="mx-1 mb-1"
                        src={deleteIcon}
                        alt="delete"
                        width="15px"
                        height="15px"
                      />
                      <span className="mx-1">Delete</span>
                    </div>
                  </button>
                </div>

                {/* update */}
                <div className="button-container">
                  <span className="button-label"></span>
                  <button className="Btn updateBtn " onClick={toAppUpdateForm}>
                    <div>
                      <img
                        className="mx-1 mb-1"
                        src={editIcon}
                        alt="new"
                        width="14px"
                        height="14px"
                      />

                      <span className="mx-1">Edit</span>
                    </div>
                  </button>
                </div>
              </>
            ) : (
              <div className="button-container">
                <span className="button-label"></span>
                <button className="Btn createBtn" onClick={handleAppModalOpen}>
                  <div>
                    <img
                      className="mx-1 mb-1"
                      src={plusIcon}
                      alt="new"
                      width="14px"
                      height="14px"
                      title="New"
                    />
                  </div>
                  <span className="mx-1">New</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <AppCreateDialog 
            appModalOpen={appModalOpen} 
            closeAppModal={handleAppModalClose} />
      </div>

      <div>
        <AppUpdateDialog
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          showModal={showUpdateModal}
          setShowModal={setShowUpdateModal}
          gridRef
        />
      </div>
      <div className="table-layout">
        <div className="table-container">
          <div
            className="ag-theme-alpine"
            style={{ height: "100%", width: "100%" }}
          >
            <AgGridReact
              //  gridOptions={gridOptions}
              ref={gridRef}
              rowData={responseData}
              columnDefs={columnDefsData}
              rowSelection="single"
              // rowMultiSelectWithClick={true}
              onSelectionChanged={onSelectionChanged}
              defaultColDef={defaultColDefs}
              onGridReady={onGridReady}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
              onCellValueChanged={onCellValueChanged}
              onCellEditingStarted={onCellEditingStarted}
              onCellEditingStopped={onCellEditingStopped}
              headerHeight={45}
              rowHeight={45}
              pagination={true}
              suppressPaginationPanel={true}
              paginationPageSize={pageSize}
              suppressDragLeaveHidesColumns={true}
              onFirstDataRendered={(params) => onFirstDataRendered(params)}
              onFilterChanged={(params) => onFilterChange(params, table)}
              onRowDataChanged={(params)=>onRowDataChanged(params)}
              onDragStopped={(params) => onDragStopped(params, table)}
              onSortChanged={(params) => onDragStopped(params, table)}
              undoRedoCellEditing={true}
            ></AgGridReact>
          </div>
        </div>

        <PaginationInfo
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        gridApi={gridApi}
        table={table}
        totalPage={initialTotalPage}
      />
      </div>
    </>
  );
};

export default AppCrud;
