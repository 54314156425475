import React from "react";
import { useSelector,useDispatch } from "react-redux";
import CardBox from "./DashboardCard";

import {
  ediIconBlack,
  jobrunnerIconBlack,
  customIconBlack,
  dataflowIconBlack,
} from "../../styling_files/newUiStyles/icons";
import { BreadCrumbs } from "../../components/crud/crudTableComponents/breadCrumbs";

// importing actions

import { OpenCloseSidebar,SetSelectedAppType } from "../../Redux/sidebar/sidebarControl/sidebarControlActions";





const DashboardView = () => {


  const dispatch=useDispatch()

  const currentUser = useSelector(
    (state) => state.CurrentUser?.CurrentUser?.full_name
  );
  const apps_for_user = useSelector((state) => state.appsForRoles.appsForRoles);
  const app_types = apps_for_user.map((item) => item.application_type);


  const handleSidebar=(type)=>{

    dispatch(OpenCloseSidebar(true))
    dispatch(SetSelectedAppType(type))
  }

  return (
    <div className="dashboard-layout">
      <BreadCrumbs />

      <div class="dashboard-container">
        <div className="row">
          <div className="col-sm-12 my-1 welcome">
            <span>Welcome back, {currentUser?.split(" ")[0]}</span>
            <span>Here's the overview of your applications</span>
          </div>
          <div>
            <div className="dashboard-divider"></div>
          </div>
        </div>

        <div className="card-container">
          <div className="row">
            {app_types.includes("crud") && 
              <div className="col-sm-6">
                <CardBox
                  title="Webeditor"
                  app_type="crud"
                  handleSidebar={handleSidebar}
                  icon={dataflowIconBlack}
                  description="Regular CRUD Apps"
                  link="Load Webeditor"
                  to="/"
                />
              </div>
            }

            
              {app_types.includes("edi") && 
                <div className="col-sm-6">
                <CardBox
                  title="EDI"
                  app_type='edi'
                  handleSidebar={handleSidebar}
                  icon={ediIconBlack}
                  description="EDI apps"
                  link="Load EDI"
                  to="/"
                />
                </div>
              }
            

           
              {app_types.includes("airflow") && 
                 <div className="col-sm-6">
                <CardBox
                  title="Job Runner"
                  app_type='airflow'
                  handleSidebar={handleSidebar}
                  icon={jobrunnerIconBlack}
                  description="Job runner apps"
                  link="Load Job Runner"
                  to="/"
                />
                 </div>
              }
           

           
              {app_types.includes("custom") && 
                 <div className="col-sm-6">
                <CardBox
                  title="Custom Application"
                  app_type='custom'
                  handleSidebar={handleSidebar}
                  icon={customIconBlack}
                  description="Custom applications"
                  link="Load Custom"
                  to="/"
                />
                </div>
              }
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
