import React, { useEffect } from 'react';
import {Routes,Route} from 'react-router-dom'
import { useParams,useNavigate} from "react-router-dom";
import { errorHandler } from '../../api/errorHandler';

import { GetSingleApp } from '../../api/appCrudApi';

const AppTableRoutes=()=>{
    
    let navigate=useNavigate()

   
    let {app_type}=useParams()
    let {appName}=useParams()
    let {appID}=useParams()
    let {tableName}=useParams()
    let {allowedTableID}=useParams()



    const generic_url=`/generictable/${appName.toLocaleLowerCase()}/${appID}/${tableName.toLocaleLowerCase()}/${allowedTableID}`

    const custom_url=`/customtable/${appName}/${appID}/${tableName}/${allowedTableID}`
    
    const airflow_url=`/jobrunner/${appName}/${appID}/${tableName}/${allowedTableID}`

    //call api to ckeck if a table is custom


    useEffect(()=>{
        

        if(app_type==='custom'){

           
            
            navigate(custom_url)
            

        }

        else if(app_type==='airflow'){
            navigate(airflow_url)

        }
        else {
          
            navigate(generic_url)

        }

    },[])
   
  
   



return null;

    

}
export default AppTableRoutes