

import React,{useState,useEffect,useRef} from 'react'



import RoleCreateDialog from './roleCreateDialog';
import RoleUpdateDialog from './roleUpdateDialog';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {useDispatch,useSelector} from 'react-redux'
import {GetRoleAction,DeleteRoleAction, UpdateRoleAction}from '../../../Redux/roles/roleAction'

import { SetCurrentAppTableAction } from "../../../Redux/currentAppTable/actions"
import {ClearDagState} from '../../../Redux/loader/LoaderAction'

import { onFilterChange,
        onFilterTextChange,
        RenderState,
        handleReactPagination,
        onDragStopped} 
        
    from '../tableState'


//importing crud components
import AgGridPageSize from "../crudTableComponents/agGridPageSize";


import SearchField from "../crudTableComponents/searchField";
import PaginationInfo from '../crudTableComponents/pagintaiton';

import {downloadIcon,plusIcon,editIcon,deleteIcon} from '../../../styling_files/newUiStyles/icons'

import { BreadCrumbs } from '../crudTableComponents/breadCrumbs';

import { openRole,closeRole,addRoute} from '../../../Redux/modals/modalActions';




const RoleCrud=()=>{

    const table='role'   
    const roleModalOpen=useSelector(state=>state.modals.roleModalOpen)
    const totalPageSaved=localStorage.getItem(`${table}_totalPage`)
    let [pageInfo,setPageInfo]=useState({currentPage:0,totalPage:totalPageSaved||1})

    let [initialTotalPage,setInitialTotalPage]=useState(0)

    let [quickFilterText,setQuickFilterText]=useState(null)
    let [pageSize,setPageSize]=useState(null)


    let [selectedID,setSelectedID]=useState(null)

    let [showUpdateModal,setShowUpdateModal]=useState(false)
    
    
    const dispatch=useDispatch()
    const gridRef=useRef()


    let responseData=useSelector(state=>state.roles.roles)
    
    useEffect(()=>{
        
        dispatch(GetRoleAction()) 
    
       
    },[])


  useEffect(()=>{

    dispatch(SetCurrentAppTableAction({
      currentTable:'Role Table',
      currentApp:'Settings'
    }))
    dispatch(ClearDagState())

  },[])


    //grid api states
    const [gridApi,setGridApi]=useState(null)
    const [gridColumnApi,setGridColumnApi]=useState(null)

    useEffect(()=>{
        let column_state = JSON.parse(localStorage.getItem(`${table}_column_state`));
        gridColumnApi?.applyColumnState({state:column_state, applyOrder: true})
    
      },)
    



  // Define the initial grid options
  const [gridOptions, setGridOptions] = useState({
    suppressCellDeselection: false,
  });

  // Define the onCellEditingStarted and onCellEditingStopped event handlers
  const onCellEditingStarted = () => {
    setGridOptions({
      ...gridOptions,
      suppressCellDeselection: true,
    });
  };

  const onCellEditingStopped = () => {
    setGridOptions({
      ...gridOptions,
      suppressCellDeselection: false,
    });
  };



  
   //column definations 
   const columnDefsData = [
    { headerName:'Role ID',field: 'id' ,checkboxSelection:true,filter: "agNumberColumnFilter"},
    { headerName:'Role Name',field: 'name',editable:true,filter:'agTextColumnFilter' },
    { headerName:'Description',field:'description',editable:true,filter:'agTextColumnFilter' }
];

    //dafault column def
   const defaultColDefs={
    minWidth:200,
    headerClass:'header-class',
    cellClass:'cell-class',
    flex:1,
    sortable:true,
    resizable:true,
    icons: {
        sortAscending: '<i class="fa fa-sort-up" style="color:white"/>',
        sortDescending: '<i class="fa fa-sort-down" style="color:white"/>',
      }
   
   }

   const onRowDataChanged = (params) => {
    setInitialTotalPage(params.api?.paginationGetTotalPages())
  };

   //grid Ready
   const onGridReady=(params)=>{
        
        setGridApi(params.api)
        setGridColumnApi(params.columnApi)
   }
   const onFirstDataRendered = (params) => {
    RenderState(params, table, setQuickFilterText, setPageSize);
  };

        

    
    //handling selection of row

    const onSelectionChanged=(e)=>{
        const row=e.api.getSelectedRows()
        const selectedid=row[0]? row[0].id:null 
        setSelectedID(selectedid)
       
    }

    //delete user

      const DeleteRole=()=>{
        if(selectedID){
            console.log(selectedID)
            const confirm=window.confirm(`Do you want to delete the row with id :${selectedID}?`)
            if (confirm){
                setSelectedID(null)
               dispatch(DeleteRoleAction(selectedID))
               
              }
        }
        else{
            alert('please Select a row')
        }

        
    }

     
    //handling modals

    const handleRoleModalOpen=()=>{
      dispatch(openRole())
      dispatch(addRoute("/dashboard/settings/roles"))   
    }
    const handleRoleModalClose=()=>{
      dispatch(closeRole())
    }
   

    const toRoleUpdateForm=()=>{
      
       setShowUpdateModal(true)
    }
    
    //page size

    const onPageSizeChange=(pgSize)=>{
        gridApi.paginationSetPageSize(Number(pgSize))
        setPageSize(pgSize)
        localStorage.setItem(`${table}_currentPageSize`, JSON.stringify(pgSize))
        

        //current page state change

        handleReactPagination(gridApi,pageInfo,setPageInfo,table)
        
       }
   //Exporting

   const onExportasCSV=()=>{
     gridApi.exportDataAsCsv()

   }

   const onCellValueChanged = (params) => {
    const updateData=params.data
    gridRef.current.api.deselectAll()
    dispatch(UpdateRoleAction(updateData,params.data.id,gridRef))
   
  };

   
  
  return(
    <>
    <BreadCrumbs/>

  <div className="title-layout">
    <div className='title-container'>
    <div className='current-app-table'>
            <p>Roles Table</p>

    </div>
    </div>
   
  </div>

  

  <div className='actions-layout'>
    <div className='actions-container-backdrop'>
      
      <div className='actions-container'>
      <div className="searchfield-container">
        <span className="button-label">Search Data</span>
        <SearchField
          quickFilterText={quickFilterText}
          setQuickFilterText={setQuickFilterText}
          onFilterTextChange={onFilterTextChange}
          gridApi={gridApi}
          table={table}
        />
        </div>
       
       
       <div className="pagesize-container" >
        <span className="button-label">Rows</span>
        
          <AgGridPageSize 
           pageSize={pageSize}
           onPageSizeChange={onPageSizeChange}
           
          />
       
       </div>
       
        
         
       <div className="line-seperator-container">
        
        <div className="line-seperator"></div>   
        </div>


        <div className="button-container">
             
             <span className="button-label"></span>
             {/* export button */}
             <button 
             className="Btn DownloadBtn "
             onClick={onExportasCSV}
             >   
             <div>
               <img 
               className="mx-1 mb-1"
               src={downloadIcon}
               alt='csv'
                width='17px' 
                height='17px'
                />
               <span className="mx-1">Export CSV</span>
               </div>
             </button>
           </div>
   
           <div className="line-seperator-container">
           
           <div className="line-seperator"></div>   
           </div>

           
           {
            selectedID?

            <>
            <div className="button-container">
            <span className="button-label"></span>
            <button 
              className="Btn deleteBtn"
              onClick={DeleteRole}>
            <div>
            <img 
            className="mx-1 mb-1"
            src={deleteIcon} 
            alt='delete'
             width='15px' 
             height='15px'
             />
            <span className="mx-1">Delete</span>
            </div>
            
          </button>

            </div>
            
            <div className="button-container">
            <span className="button-label"></span>
            <button 
              className="Btn updateBtn "
              onClick={toRoleUpdateForm}
            >

            <div>
            <img 
            className="mx-1 mb-1"
            src={editIcon}
            
            alt='new'
            width='14px' 
            height='14px'
            
             />

            <span className="mx-1">Edit</span>
            
            </div>
          </button>

            </div>
         
          </>


          
          :

          <div className="button-container">
          <span className="button-label"></span>
          <button 
              className="Btn createBtn"
              onClick={handleRoleModalOpen}
            >

            <div>
            <img 
            className="mx-1 mb-1"
            src={plusIcon}
            alt='new'
            width='14px' 
            height='14px'
            title='New'
             />
            
            </div>
            <span className="mx-1">New</span>
           
          </button>

          </div>
      
          } 

   

  
           
       
         

          

      </div>

      
      
    </div>


  </div>
  <div >
        <RoleCreateDialog
          id={selectedID}
          roleModalOpen={roleModalOpen}
          roleModalClose={handleRoleModalClose}
         
        />
  </div>

  <div>
    <RoleUpdateDialog
     selectedID={selectedID}
     setSelectedID={setSelectedID}
     showModal={showUpdateModal}
     setShowModal={setShowUpdateModal}
     gridRef
    />
  </div>
  <div className="table-layout">
    <div className='table-container'>

    <div
          className="ag-theme-alpine"
          style={{ height: "100%", width: "100%" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={responseData}
            columnDefs={columnDefsData}
            rowSelection="single"
            // rowMultiSelectWithClick={true}
            onSelectionChanged={onSelectionChanged}
            defaultColDef={defaultColDefs}
            onGridReady={onGridReady}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
            onCellValueChanged={onCellValueChanged}
            onCellEditingStarted={onCellEditingStarted}
            onCellEditingStopped={onCellEditingStopped}
            headerHeight={45}
            rowHeight={45}
            pagination={true}
            suppressPaginationPanel={true}
            paginationPageSize={pageSize}
            suppressDragLeaveHidesColumns={true}

            onFirstDataRendered={(params) => onFirstDataRendered(params)}
            onFilterChanged={(params) => onFilterChange(params, table)}
            onRowDataChanged={(params)=>onRowDataChanged(params)}

            onDragStopped={(params) => onDragStopped(params, table)}
            onSortChanged={(params) => onDragStopped(params, table)}
            undoRedoCellEditing={true}
          ></AgGridReact>
        </div>
    </div>
    
    
  
    <PaginationInfo
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        gridApi={gridApi}
        table={table}
        totalPage={initialTotalPage}
        
      />
       
   
      
       
     
  </div>

    </>
)
} 


export default RoleCrud